import {cycles} from "../../model/cycles";

const initialState = {
    selected: cycles[0].id,
    view: 'slider'
}

export const cycle = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_CYCLE':
            return {...state, selected: action.payload}
        case 'TOGGLE_VIEW':
            return {...state, view: state.view === 'slider' ? 'pick' : 'slider'}
    }

    return state;
}

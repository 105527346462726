import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(16, 0, 6),
    },
}));

export const Introduction = () => {
    const classes = useStyles();

    return <Container maxWidth="md" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            Pick Your <span style={{whiteSpace: 'nowrap'}}>Contract Offer</span>
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet atque blanditiis debitis dicta
            dolore, ducimus ea eius explicabo hic illum labore laudantium magni officiis possimus quos
            reprehenderit, sint temporibus ullam.
        </Typography>
    </Container>;
}

import {useSelector} from "react-redux";
import {SliderView} from "../layout/slider-view";
import React from "react";
import {PickOfferView} from "../layout/pick-offer-view";

export const ContractOffer = () => {
    const view = useSelector(state => state.cycle.view)

    return (
        <>
            {view === 'slider' && <SliderView />}
            {view === 'pick' && <PickOfferView />}
        </>
    )
}

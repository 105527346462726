import Grid from "@material-ui/core/Grid";
import {CycleSlider} from "../components/CycleSlider";
import React from "react";
import {CycleCard} from "../components/CycleCard";
import {findCycleById} from "../model/cycles";
import {useSelector} from "react-redux";

export const SliderView = () => {
    const selectedCycle = useSelector(state => state.cycle.selected)

    return <>
        <Grid container spacing={5} justify={"center"}>
            <Grid item xs={9} sm={10} md={10}>
                <CycleSlider/>
            </Grid>
        </Grid>
        <Grid container spacing={5} justify={"center"}>
            <Grid item xs={12} sm={8} md={8}>
                <CycleCard cycle={findCycleById(selectedCycle)} multiple={false}/>
            </Grid>
        </Grid>
    </>;
}

import Slider from "@material-ui/core/Slider";
import React from "react";
import {cycles} from "../model/cycles";
import {changeCycle} from "../redux/actions";
import {connect} from "react-redux";

const mapDispatchToProps = (dispatch) => {
    return {
        onChange: (e, value) => dispatch(changeCycle(cycles[value].id)),
    }
}

const marks = cycles.map((cycle, index) => ({label: cycle.label, value: index}))

const CycleSliderComponent = ({onChange}) => {

    const valueText = value => cycles[value].training + ' months'

    return <Slider color={"secondary"}
                   defaultValue={0}
                   getAriaValueText={valueText}
                   aria-labelledby="discrete-slider-restrict"
                   min={0}
                   max={cycles.length - 1}
                   valueLabelDisplay="off"
                   marks={marks}
                   onChange={onChange}/>
}

export const CycleSlider = connect(null, mapDispatchToProps)(CycleSliderComponent)

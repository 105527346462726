import Grid from "@material-ui/core/Grid";
import {CycleCard} from "../components/CycleCard";
import React from "react";
import {cycles} from "../model/cycles";

export const PickOfferView = () => {
    return <Grid container spacing={5} alignItems="flex-end" justify={"center"}>
        {cycles.map((cycle, index) => {
            const xsNbCols = index === 1 ? 11 : 10;
            const smNbCols = index === 1 ? 9 : 8;
                return <Grid item key={index} xs={xsNbCols} sm={smNbCols} md={4}>
                    <CycleCard cycle={cycle} multiple={true}/>
                </Grid>
        })}
    </Grid>
}

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
}));

export const Footer = () => {
    const classes = useStyles();

    return <Container maxWidth="md" component="footer" className={classes.footer}>
        <Box mt={5}>
            <Typography align={"center"}>
                Solutions Majisti ©️ {new Date().getFullYear()}
            </Typography>
        </Box>
    </Container>
}

export const cycles = [
        {
            id: 'shortTerm',
            label: 'Short term cycle',
            training: 3,
            consulting: '1 year',
            salaryRaiseInPercent: 10,
            backToOfficeTerm: 3,
            popular: false,
        },
        {
            id: 'regularTerm',
            label: 'Regular cycle',
            subheader: 'Most Common',
            training: 6,
            consulting: '1 year and 6 months',
            cycle: 6,
            salaryRaiseInPercent: 15,
            backToOfficeTerm: 6,
            popular: true,
        },
        {
            id: 'longTerm',
            label: 'Long term cycle',
            training: 12,
            consulting: '2 years',
            salaryRaiseInPercent: 20,
            backToOfficeTerm: 6,
            popular: false,
        }
    ]

export const findCycleById = (id) => cycles.find(cycle => cycle.id === id)

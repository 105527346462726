import {compose, createStore} from "redux";
import {rootReducer} from "./reducers/root";

export const initStore = () => {
    const windowGlobal = typeof window !== 'undefined' && window

    const devtools =
        process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension
            ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
            : f => f;

    return createStore(
        rootReducer,
        compose(
            devtools,
        )
    );
}

import React from 'react';
import Container from "@material-ui/core/Container";
import {Provider} from 'react-redux';
import {TopBar} from "../layout/top-bar";
import {Introduction} from "../layout/introduction";
import {Footer} from "../layout/footer";
import {initStore} from "../redux/store";
import {makeStyles} from "@material-ui/core/styles";
import {ContractOffer} from "../components/ContractOffer";

const useStyles = makeStyles(() => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
}));

export default () => {
   useStyles();

    return (
        <Provider store={initStore()}>
            <TopBar/>

            <Introduction/>

            <Container maxWidth={"md"} component={"main"}>
                <ContractOffer />
            </Container>

            <Footer/>
        </Provider>
    );
}

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MajistiLogo from "../../plugins/gatsby-plugin-layout/themes/majisti/logo.svg";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {toggleView} from "../redux/actions";

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    navButton: {
        margin: theme.spacing(1, 0.5),
    },
}));


export const TopBar = () => {
    const classes = useStyles();

    const dispatch = useDispatch()
    const view = useSelector(state => state.cycle.view)

    const onClick = () => dispatch(toggleView())

    return <AppBar position="fixed" color="default" elevation={3} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
            <img src={MajistiLogo} className="logo" alt="Majisti Logo" width="60" height="60"
                 style={{marginRight: '10px'}}/>
            <Typography variant="h4" color="inherit" noWrap className={classes.toolbarTitle}>
                Solutions Majisti
            </Typography>
            <nav>
                <Button variant="outlined" color={"primary"} disabled={view === 'pick'} onClick={onClick}
                        className={classes.navButton}>
                    Pick Your Offer
                </Button>
                <Button variant="outlined" color={"primary"} disabled={view === 'slider'} onClick={onClick}
                        className={classes.navButton}>
                    Slider
                </Button>
            </nav>
        </Toolbar>
    </AppBar>;
}


import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import StarIcon from "@material-ui/icons/StarBorder";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    starIcon: {
        color: theme.palette.secondary.main,
        width: '1.2em',
        height: '1.2em',
    }
}));

const mapStateToProps = (state) => {
    return {
        selectedCycle: state.cycle.selected
    }
}

const CycleCardComponent = ({cycle, multiple = true}) => {
    const classes = useStyles();

    return (
        <Card>
            <CardHeader
                title={cycle.label}
                subheader={multiple ? cycle.subheader : `${cycle.training} months`}
                titleTypographyProps={{align: 'center'}}
                subheaderTypographyProps={{align: 'center'}}
                action={cycle.value === 1 ? <StarIcon className={classes.starIcon}/> : null}
                className={classes.cardHeader}/>

            <CardContent>
                <ul>
                    {multiple &&
                    <Typography component="li" variant="h5" align="center">
                        {cycle.training} months training
                    </Typography>
                    }
                    <Typography component="li" variant="subtitle1" align="center">
                        Consult <strong>{cycle.consulting}</strong>
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center">
                        <strong>{cycle.salaryRaiseInPercent}%</strong> base salary raise increase
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center">
                        Back to office, up to <strong>{cycle.backToOfficeTerm} months</strong> before consulting again
                    </Typography>
                </ul>
            </CardContent>
            <CardActions>
                <Button fullWidth variant={cycle.popular ? 'contained' : 'outlined'} color="secondary">
                    Learn More...
                </Button>
            </CardActions>
        </Card>
    )
}

export const CycleCard = connect(mapStateToProps, {})(CycleCardComponent)











